<template>
  <div class="ulcenter">
  <ul>
      <li><router-link to="/home">Home</router-link></li>
      <li><router-link to="/addresto">Add Restaurent</router-link></li>
      <li @click="logout">Logout</li>
  </ul>
  </div>
</template>


<script>
export default {
  name : 'Header',
  methods : {
    logout() {
      localStorage.clear();
      this.$router.push({name:'Login'});
    }
  }
}
</script>

<style>
.ulcenter  { margin: 0 auto; width: 430px; }
ul li {  float: left; text-align: center; margin-right: 20px; list-style: none; cursor: pointer; }
</style>
