import Home from './components/Home.vue';
import Signup from './components/Signup.vue';
import Login from './components/Login.vue';
import Addresto from './components/Addresto.vue';
import Updateresto from './components/Updateresto.vue';


import { createRouter, createWebHistory } from "vue-router";


const routes = [
  {
    //name : "Login",
    //component : Login,
    path : "/",
    redirect: '/login'
  },
  {
    name : "Login",
    component : Login,
    path : "/login",
  },
  {
    name : "Home",
    component : Home,
    path : "/home",
  },
  {
    name : "Signup",
    component : Signup,
    path : "/signup",
  },
  {
    name : "Addresto",
    component : Addresto,
    path : "/addresto",
  },
  {
    name : "Updateresto",
    component : Updateresto,
    path : "/updateresto/:id",
  }

];

const router = createRouter({
  history : createWebHistory(),
  routes,
});

export default router;
