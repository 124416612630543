<template>
  <h2 class="aligncenter">Hi {{ name }}, Welcome to Update Resto Page</h2>
  <Header />

  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <form>
  <table>
    <tr>
      <td><b>Resto Name</b></td>
      <td><input type="text" name="name" v-model="resto.name" /></td>
    </tr>
    <tr>
      <td><b>City</b></td>
      <td><input type="text" name="city" v-model="resto.city" /></td>
    </tr>
    <tr>
      <td><b>Pincode</b></td>
      <td><input type="text" name="pincode" v-model="resto.pincode" /></td>
    </tr>

    <tr>
      <td><b></b></td>
      <td><input type="button" name="pincode" value="Update" @click="updateresto" /></td>
    </tr>

  </table>
  </form>
</template>




<script>
import Header from './Header.vue'
import axios from 'axios'

export default {
  name : 'Updateresto',
  data() {
    return {
      name : '',
      resto : {
        name:'',
        city:'',
        pincode:''
      }
    }
  },
  components : {
    Header
  },
  methods : {
    async updateresto() {
      //console.warn(this.resto);
      const result = await axios.put('http://localhost:3004/resto/'+this.$route.params.id,{
        name : this.resto.name,
        city : this.resto.city,
        pincode : this.resto.pincode,
      });

      if(result.status==200) {
        this.$router.push({name:'Home'});
      }
    }
  },
  async mounted () {
    let user = localStorage.getItem("userinfo");
    this.name = JSON.parse(user).name;
    if(!user) {
      this.$router.push({name:"Signup"});
    }

    const result = await axios.get('http://localhost:3004/resto/'+this.$route.params.id);
    //console.warn(this.$route.params.id);
    //console.warn(result.data);
    this.resto = result.data;
  }
}
</script>



<style>
</style>
