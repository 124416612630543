<template>
  <h2 class="aligncenter">Hi {{ name }}, Welcome to Home Page</h2>
  <Header />

  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <table>
    <tr>
      <td><b>ID</b></td>
      <td><b>Resto Name</b></td>
      <td><b>City</b></td>
      <td><b>Pincode</b></td>
      <td><b>Action</b></td>
    </tr>
    <tr v-for="item in resto" :key="item.id">
      <td>{{ item.id }}</td>
      <td>{{ item.name }}</td>
      <td>{{ item.city }}</td>
      <td>{{ item.pincode }}</td>
      <td><router-link :to="'/updateresto/'+item.id"><a>Edit</a></router-link>  |
          <a @click="deleteresto(item.id)">Delete</a>
      </td>
    </tr>
  </table>
</template>



<script>
import Header from './Header.vue'
import axios from 'axios'

export default {
  name : 'Home',
  data() {
    return {
      name : '',
      resto : [],
    }
  },
  components : {
    Header
  },
  methods : {
    async deleteresto(id) {
      //console.warn(id);
      let result = await axios.delete("http://localhost:3004/resto/"+id);
      //console.warn(result.status);
      if(result.status==200) {
        this.loaddata();
      }

    },

    async loaddata() {
      let user = localStorage.getItem("userinfo");
       //alert(user);
       this.name = JSON.parse(user).name;

      if(!user) {
        this.$router.push({name:"Signup"});
      }


      let result = await axios.get("http://localhost:3004/resto");
      //console.warn(result);
      this.resto = result.data;
    }
  },
  async mounted () {
      this.loaddata();
  }
}
</script>



<style>
table  { width:100%; marign-top:20px; }
table tr td { border:1px solid #ccc; padding:10px; }
a { cursor: pointer; }
</style>
