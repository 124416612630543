<template>
  <h2 class="aligncenter">Login</h2>

  <input type="text"  v-model="email" placeholder="Enter Email" class="inpform" />
  <input type="password" v-model="password"  placeholder="Enter Password" class="inpform" />
  <input type="submit" @click="login" value="Login" class="submitbutt" />

  <p class="aligncenter"><router-link to="/signup">Signup</router-link></p>
</template>


<script>
import axios from 'axios'

export default {
  name : "Login",
  data()
  {
    return {
      email : '',
      password : ''
    }
  },
  methods : {
    async login() {
      //console.warn("User",this.email,this.password);
      let result = await axios.get(`http://localhost:3004/user?email=${this.email}&password=${this.password}`);


      if(result.status==200 && result.data.length>0) {
        localStorage.setItem("userinfo",JSON.stringify(result.data[0]));
        this.$router.push({name : 'Home'});
      }
      console.warn(result);
    }
  },
  mounted()
  {
    let user = localStorage.getItem("userinfo");
    if(user) {
      this.$router.push({name : 'Home'});
    }
  }
}
</script>


<style>
</style>
