<template>
  <h2 class="aligncenter">Hi {{ name }}, Welcome to Add Resto Page</h2>
  <Header />

  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <form>
  <table>
    <tr>
      <td><b>Resto Name</b></td>
      <td><input type="text" name="name" v-model="resto.rname" /></td>
    </tr>
    <tr>
      <td><b>City</b></td>
      <td><input type="text" name="city" v-model="resto.rcity" /></td>
    </tr>
    <tr>
      <td><b>Pincode</b></td>
      <td><input type="text" name="pincode" v-model="resto.rpincode" /></td>
    </tr>

    <tr>
      <td><b></b></td>
      <td><input type="button" name="pincode" value="Add" @click="addresto" /></td>
    </tr>

  </table>
</form>

</template>



<script>
import axios from 'axios'
import Header from './Header.vue'

export default {
  name : 'Addresto',
  data() {
    return {
      name : '',
      resto : {
        rname:'',
        rcity:'',
        rpincode:''
      }
    }
  },
  methods : {
      async addresto() {
        //console.warn(this.resto);
        let result = await axios.post("http://localhost:3004/resto",{
          name:this.resto.rname,
          city:this.resto.rcity,
          pincode:this.resto.rpincode,
        });

        if(result.status==201) {
          this.$router.push({name:"Home"});
        }

      }
  },
  components : {
    Header
  },
  mounted () {
    let user = localStorage.getItem("userinfo");
    this.name = JSON.parse(user).name;
    if(!user) {
      this.$router.push({name:"Signup"});
    }
  }
}
</script>



<style>
input { height: 35px; width: 320px; }
</style>
