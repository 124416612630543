<template>
  <h2 class="aligncenter">Signup</h2>

  <input type="text" v-model="name" placeholder="Enter Name" class="inpform" />
  <input type="text"  v-model="email" placeholder="Enter Email" class="inpform" />
  <input type="password" v-model="password"  placeholder="Enter Password" class="inpform" />
  <input type="submit" @click="signup" value="Register" class="submitbutt" />

  <p class="aligncenter"><router-link to="/login">Login</router-link></p>
</template>

<script>
import axios from 'axios'

export default {
  name : 'Signup',
  data()
  {
    return {
      name : '',
      email : '',
      password : ''
    }
  },
  methods : {
    async signup() {
      //console.warn("Signup", this.name, this.email, this.password);
      let result = await axios.post("http://localhost:3004/user",{
        name:this.name,
        email:this.email,
        password:this.password
      });

      console.warn(result);
      if(result.status==201){
        localStorage.setItem("userinfo",JSON.stringify(result.data));
        this.$router.push({name:"Home"});
        //alert("Registered Successfully");
      } else {
        alert("Please Try Again");
      }
    }
  },
  mounted () {
    let user = localStorage.getItem("userinfo");
    if(user) {
      this.$router.push({name:"Home"});
    }
  }
}
</script>


<style>
.aligncenter { text-align: center; }
.inpform { height: 35px; padding-left: 10px; display: block; margin: 0 auto; margin-bottom: 15px; width: 320px; }
.submitbutt   { height: 35px; padding: 5px; width: 335px;  cursor: pointer; margin: 0 auto; display: block; }
</style>
